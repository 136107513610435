import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import { useState, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@emotion/react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useQueryClient } from 'react-query';

// Our Components
import ColumnItem from 'components/DataTable/ColumnItem';
import DataTable from 'components/DataTable/DataTable';
import DeleteLiabilityModal from 'components/Modal/WalletOverview/DeleteLiabilityModal';
import ExportCsvButton from 'components/Button/Export/ExportCsvButton';
import { TertiaryButton } from 'components/Button/Button';
import TotalLiabilitiesActionButtons from 'components/SoraWallet/Cards/totalLiabilitiesCardComponents/TotalLiabilitiesActionButtons';

// Our Utils
import { dollarFormatter, getFormattedLiabilityRate } from 'shared/utils';
import defaultLiabilitiesSortOrder from 'shared/utils/sorting/defaultLiabilitiesSortOrder';
import getLiabilityConfidence from 'shared/utils/calculations/getLiabilityConfidence';
import normalizeLoanType from 'shared/utils/clientOnboarding/normalizeLoanType';
// Routes
import {
	ADVISOR_NEW_LOAN_SELECTION_ROUTE,
	CLIENT_NEW_LOAN_SELECTION_ROUTE,
	ADVISOR_WALLET_LIABILITY,
	CLIENT_WALLET_LIABILITY
} from 'routes';

// Query Keys
import { USERPROFILEDATA } from 'shared/query-keys';

// Our Constants
import {
	AUTO,
	CREDITCARD,
	COLLECTION,
	HELOC,
	MORTGAGE,
	STUDENT,
	COMMERCIAL
} from 'shared/constants';

// Local Constants
const CONFIDENCE_ORDER = {
	High: 1,
	Medium: 2,
	Low: 3
};

const INVALID_WALLET_TYPES = new Set([COLLECTION, CREDITCARD, COMMERCIAL]);

// Helper Functions
const getFormattedUpdatedDate = (updatedDate) => {
	if (updatedDate) {
		return format(new Date(updatedDate), 'MM/dd/yyyy');
	}

	return 'NA';
};

const getLiabilityConfidenceColor = (confidenceLevel) => {
	const confidenceToColorMapping = {
		Low: 'red',
		Medium: '#bfa70a',
		High: 'green',
		manual: 'green',
		Manual: 'green'
	};

	return confidenceToColorMapping[confidenceLevel] ?? 'red';
};

const dataConfidenceSortComparator = (confidenceOne, confidenceTwo) => {
	const orderOne = CONFIDENCE_ORDER[confidenceOne] ?? 4; // Default to 4 if not found
	const orderTwo = CONFIDENCE_ORDER[confidenceTwo] ?? 4; // Default to 4 if not found

	return orderOne - orderTwo;
};

function TotalLiabilitiesTable({
	bizLoans,
	clientId,
	clientData,
	totalLiabilities,
	liabilities
}) {
	const SoraTheme = useTheme();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [liabilitySelected, setLiabilitySelected] = useState(null);

	const profileData = queryClient.getQueryData(USERPROFILEDATA);
	const isAdvisor = profileData?.role === 'ADVISOR';

	const toggleModal = (mode) => setIsModalOpen(mode ?? !isModalOpen);

	const handleClose = () => {
		toggleModal(false);
		setLiabilitySelected(null);
	};

	const path = isAdvisor
		? ADVISOR_NEW_LOAN_SELECTION_ROUTE
		: CLIENT_NEW_LOAN_SELECTION_ROUTE;

	const payload = isAdvisor ? { clientId } : {};

	const updateRoute = useMemo(() => generatePath(path, payload), [clientId]);

	const handleClick = (tradelineId) => {
		if (!isAdvisor) {
			navigate(`${CLIENT_WALLET_LIABILITY}${tradelineId}`);
			return;
		}

		// not a client
		navigate(
			`${ADVISOR_WALLET_LIABILITY}${clientId}/liability/${tradelineId}`
		);
	};

	const formattedTotalLiabilities = dollarFormatter.format(totalLiabilities);

	const typeKey = 'tradeLineType';
	const detailsKey = 'lender';
	const rateKey = 'interestRate';
	const balanceKey = 'outstandingBalance';
	const monthlyPayKey = 'monthlyPay';
	const dataConfidenceKey = 'liabilityConfidence';
	const lastUpdatedKey = 'lastUpdatedDate';

	const totalLiabilitiesData = useMemo(() => {
		const liabilitiesAndBizLoan = [...liabilities, ...bizLoans].map(
			(userLiabilityData) => {
				const isMortgage = userLiabilityData.tradeLineType === MORTGAGE;
				if (isMortgage) {
					return {
						...userLiabilityData,
						monthlyPay: userLiabilityData.monthlyMortgagePayment,
						liabilityConfidence: getLiabilityConfidence(
							userLiabilityData.interestRateSource,
							userLiabilityData.rateManuallyUpdated
						),
						id: uuidv4()
					};
				}

				return {
					...userLiabilityData,
					liabilityConfidence: getLiabilityConfidence(
						userLiabilityData.interestRateSource,
						userLiabilityData.rateManuallyUpdated
					),
					id: uuidv4()
				};
			}
		);

		return defaultLiabilitiesSortOrder(liabilitiesAndBizLoan);
	}, [liabilities, bizLoans]);

	const totalLiabilitiesColumns = [
		ColumnItem(typeKey, 'Type', 1.1, {
			valueFormatter: (params) => {
				const currentTradelineType = params.value;
				if (currentTradelineType === CREDITCARD) return 'Credit Card';

				const formattedType = normalizeLoanType(currentTradelineType);
				return formattedType;
			}
		}),
		ColumnItem(detailsKey, 'Details', 1.1),
		ColumnItem(rateKey, 'Rate', 0.6, {
			valueFormatter: (params) => getFormattedLiabilityRate(params.value)
		}),
		ColumnItem(balanceKey, 'Balance', 1, {
			valueFormatter: (params) => dollarFormatter.format(params.value)
		}),
		ColumnItem(monthlyPayKey, 'Monthly Payment', 1, {
			valueFormatter: (params) => dollarFormatter.format(params.value)
		}),
		ColumnItem(dataConfidenceKey, 'Data Confidence', 1, {
			renderCell: ({ value }) => {
				const color = getLiabilityConfidenceColor(value);
				return (
					<Typography variant="body2" sx={{ color }}>
						{value}
					</Typography>
				);
			},
			sortComparator: dataConfidenceSortComparator
		}),
		ColumnItem(lastUpdatedKey, 'Last Updated', 1, {
			valueFormatter: (params) => getFormattedUpdatedDate(params.value)
		}),
		ColumnItem('id', '', 0.6, {
			sortable: false,
			renderCell: ({ row }) => (
				<TotalLiabilitiesActionButtons
					liabilitySelectedData={row}
					handleDeleteClick={() => {
						setLiabilitySelected(row);
						toggleModal(true);
					}}
				/>
			)
		})
	];

	const exportableData = totalLiabilitiesData.map(
		({
			interestRate,
			lastUpdatedDate,
			lender,
			liabilityConfidence,
			monthlyPay,
			outstandingBalance,
			tradeLineType
		}) => ({
			type:
				tradeLineType === CREDITCARD
					? 'Credit Card'
					: normalizeLoanType(tradeLineType),
			details: lender,
			rate: `${interestRate}%`,
			balance: `${dollarFormatter.format(outstandingBalance)}`,
			monthlyPayment: `${dollarFormatter.format(monthlyPay)}`,
			dataConfidence: liabilityConfidence,
			lastUpdated: lastUpdatedDate
		})
	);

	return (
		<>
			<Grid
				container
				item
				xs={12}
				sx={{
					marginBottom: 2,
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<Typography
					variant="h2Gascogne"
					sx={{
						color: SoraTheme.palette.primary.indigo,
						display: 'block'
					}}
				>
					Total Liabilities: {formattedTotalLiabilities}
				</Typography>

				<Box>
					<ExportCsvButton
						data={exportableData}
						fileName={`${clientData?.firstName}${clientData?.lastName}_liabilities.csv`}
					/>
					<Link
						to={updateRoute}
						state={{ fromWallet: true, ...clientId }}
					>
						<TertiaryButton>Add Liabilities</TertiaryButton>
					</Link>
				</Box>
			</Grid>

			<Grid item xs={12}>
				<DataTable
					disableSelectionOnClick
					columns={totalLiabilitiesColumns}
					rows={totalLiabilitiesData}
					rowsPerPageOptions={10}
					handleRowClick={({ tradelineId, tradeLineType }) => {
						if (INVALID_WALLET_TYPES.has(tradeLineType)) return;
						handleClick(tradelineId);
					}}
				/>
			</Grid>

			<DeleteLiabilityModal
				isModalOpen={isModalOpen}
				handleClose={handleClose}
				liabilitySelected={liabilitySelected}
			/>
		</>
	);
}

TotalLiabilitiesTable.propTypes = {
	totalLiabilities: PropTypes.number.isRequired
};

export default TotalLiabilitiesTable;
