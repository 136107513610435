import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';

// Our Components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import Loader from 'components/Loader';

// Our Queries
import useGetProfile from 'hooks/useGetProfile';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// Our Mutations
import useMutateExecuteLoan from 'hooks/useMutateExecuteLoan';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Our Utils
import { dollarFormatter, getFormattedLiabilityRate } from 'shared/utils';

// Our Constants
const SUPPORTED_OFFER_TYPES = ['new_loan', 'cash_out', 'refinance'];

function camelCaseToTitleCase(camelCaseArray) {
	return camelCaseArray.map((camelCaseString) => {
		// Split the camelCase string into words
		const words = camelCaseString.replace(/([A-Z])/g, ' $1').toLowerCase();
		// Capitalize the first letter of each word
		return words
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	});
}

function OfferButton({
	isLoading,
	isRoleLoading,
	profileData,
	handleCallBack
}) {
	if (isLoading || isRoleLoading)
		return <Loader boxSX={{ width: 162, justifyContent: 'center' }} />;

	const { role } = profileData;

	return (
		<PrimaryButton onClick={handleCallBack}>
			{role === 'CLIENT' ? 'Execute Loan' : 'Request More Info'}
		</PrimaryButton>
	);
}

function OffersColumnItem({ lenderLogoUrl, offerType, offerId, offerData }) {
	const queryClient = useQueryClient();
	const { state } = useLocation();
	const SoraTheme = useTheme();
	const { primary } = SoraTheme.palette;

	const { isLoading: isRoleLoading, data: profileData } = useGetProfile();

	// Mutations
	const { mutate: executeLoan, isLoading: isExecuteLoanLoading } =
		useMutateExecuteLoan();

	const {
		mutate: mutateSendGenericEmail,
		isLoading: isSendGenericEmailLoading,
		isSuccess: isSendGenericEmailSuccess
	} = useMutateSendGenericEmail();

	const handleLoanExecution = () => {
		executeLoan(offerId);
	};

	const handleSendGenericEmail = () => {
		const { existingClientOffer } = offerData;
		const { firstName: clientFirstName, lastName: clientLastName } =
			state.clientData;

		const formatterMap = {
			interestRate: getFormattedLiabilityRate,
			monthlyPayUsd: (value) => dollarFormatter.format(value),
			totalCostOfDebt: (value) => dollarFormatter.format(value),
			tenure: (value) => `${Math.floor(value / 12)} years`,
			totalLoanAmount: (value) => dollarFormatter.format(value),
			remainingMonths: (value) => `${Math.floor(value / 12)} years`,
			monthlyMortgagePayment: (value) => dollarFormatter.format(value),
			escrow: (value) => dollarFormatter.format(value),
			cashOutPotential: (value) => dollarFormatter.format(value),
			amount: (value) => dollarFormatter.format(value)
		};

		const existingOfferMessage = Object.keys(existingClientOffer)
			.sort((a, b) => a.localeCompare(b))
			.map((currentField) => {
				const currentFieldData = existingClientOffer[currentField];
				const formattedField = camelCaseToTitleCase([currentField]);

				const formattedFieldData = formatterMap[currentField]
					? formatterMap[currentField](currentFieldData)
					: currentFieldData;

				return `<li>${formattedField}: ${formattedFieldData}</li>`;
			})
			.join('');

		const newOfferMessage = Object.keys(offerData)
			.filter(
				(key) =>
					key !== 'existingClientOffer' && key !== 'lenderLogoUrl'
			)
			.sort((a, b) => a.localeCompare(b))
			.map((key) => {
				const currentFieldData = offerData[key];
				const formattedField = camelCaseToTitleCase([key]);

				const formattedFieldData = formatterMap[key]
					? formatterMap[key](currentFieldData)
					: currentFieldData;

				if (key === 'tenure') {
					return `<li>${formattedField}: ${currentFieldData}</li>`;
				}

				return `<li>${formattedField}: ${formattedFieldData}</li>`;
			})
			.join('');

		const advisorName = `${profileData?.firstName} ${profileData?.lastName}`;
		const clientName = `${clientFirstName} ${clientLastName}`;

		mutateSendGenericEmail(
			{
				sendToArray: ['lending@sorafinance.com'],
				subject: `Advisor: ${advisorName} has requested offer info for their client: ${clientName}`,
				firstName: clientFirstName,
				lastName: clientLastName,
				message: `
			<p style="margin-left: 20px;">Advisor:${advisorName} has requested offer info for client: ${clientName}.</p>	
			<br />
			<p style="margin-left: 20px;">Advisor email: ${profileData?.email}</p>
			<br />
			<p style="margin-left: 20px;">Here are the current details of the existing offer(their current liability): <ul>${existingOfferMessage}</ul></p>
			<br />
			<p style="margin-left: 20px;">Here are the details of the new refinance offer: <ul>${newOfferMessage}</ul></p>
			`
			},
			{
				onSuccess: () => {
					queryClient.setQueryData(
						SUCCESS_MESSAGE_DATA,
						'Your request has been received. Sora will reach out to you shortly with additional information.'
					);
				}
			}
		);
	};

	// const height = offerType === 'cash_out' ? 820 : 900;
	const height = 820;

	const isLoading = isExecuteLoanLoading || isSendGenericEmailLoading;

	const isClient = profileData?.role === 'CLIENT';

	return (
		<>
			{isSendGenericEmailSuccess && <Alert variant="success" />}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					height,
					zIndex: 2
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						border: `2px solid ${primary.soraBlue}`,
						borderRadius: '10px',
						alignItems: 'center',
						padding: 6,
						paddingTop: 4.5,
						height: 900
					}}
				>
					<Box
						sx={{
							height: 80,
							width: 140,
							display: 'flex',
							alignItems: 'center',
							marginBottom: 3
						}}
					>
						<Box
							component="img"
							src={lenderLogoUrl}
							sx={{ maxHeight: 80, maxWidth: 140 }}
						/>
					</Box>
					<OfferButton
						isLoading={isLoading}
						isRoleLoading={isRoleLoading}
						profileData={profileData}
						handleCallBack={
							isClient
								? handleLoanExecution
								: handleSendGenericEmail
						}
					/>
				</Box>
			</Box>
		</>
	);
}

OffersColumnItem.propTypes = {
	offerType: PropTypes.oneOf(SUPPORTED_OFFER_TYPES).isRequired,
	offerId: PropTypes.number.isRequired
};

export default OffersColumnItem;
