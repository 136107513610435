import { Box, Link as ExternalLink, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our Components
import Alert from 'components/Alert/index';
import Loader from 'components/Loader/index';
import Modal from 'components/Modal/Modal';
import LoadingSkeleton from 'components/Frame/LoadingSkelton';
import { TertiaryButton } from 'components/Button/Button';

// Our Hooks
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';
import useMutationSendNewLoanInquiryInsightEmail from 'hooks/insights/mutations/useMutationSendNewLoanInquiryInsightEmail';

// Our Selects
import selectInsightsDataFromWalletOverview from 'selectors/selectInsightsDataFromWalletOverview';

// Our Query Keys
import { SUCCESS_MESSAGE_DATA } from 'shared/query-keys';

// Our Utils
import {
	pullUrlFromInsight,
	removeLinkFromInsight,
	removePrefixFromInsight
} from 'shared/utils';

// Local Constants
const SoraMark =
	'https://prod-public-asset.s3.amazonaws.com/SoraLogos/Sora_Mark.png';

const isLinkRule = (ruleName) =>
	ruleName === 'auto_loan_rule_1' || ruleName === 'auto_loan_rule_2';

function InsightsModal({ isModalOpen, closeModal, selectedInsight, isClient }) {
	const queryClient = useQueryClient();

	const sendNewLoanInquiry =
		useMutationSendNewLoanInquiryInsightEmail(isClient);

	const {
		isLoading,
		isSuccess,
		data: insightRelatedWalletOverviewData
	} = useGetWalletOverview(selectInsightsDataFromWalletOverview);

	const { isLoading: isSendNewLoanInquiryLoading } = sendNewLoanInquiry;
	const { insightType, insightTextOutput, ruleName } = selectedInsight;

	const insightTextWithoutUrl = removeLinkFromInsight(insightTextOutput);

	const insightTextWithoutPrefix = removePrefixFromInsight(
		insightTextWithoutUrl
	);

	const insightHasLink = isLinkRule(ruleName);

	const insightLink = insightHasLink && pullUrlFromInsight(insightTextOutput);

	if (isLoading) {
		return (
			<Modal
				isOpen={isModalOpen}
				handleClose={closeModal}
				TitleComponent={
					<Box
						sx={{
							display: 'flex',
							alignItems: 'flex-end',
							gap: 2
						}}
					>
						<Box
							component="img"
							src={SoraMark}
							sx={{ height: '2.5rem' }}
						/>
						<Typography
							variant="h2Gascogne"
							sx={{ color: 'black' }}
						>
							{insightType}
						</Typography>
					</Box>
				}
			>
				<LoadingSkeleton />
			</Modal>
		);
	}

	if (isSuccess) {
		return (
			<>
				{sendNewLoanInquiry?.isSuccess &&
					queryClient.getQueryData(SUCCESS_MESSAGE_DATA) && (
						<Alert variant="success" />
					)}
				<Modal
					isOpen={isModalOpen}
					handleClose={closeModal}
					TitleComponent={
						<Box
							sx={{
								display: 'flex',
								alignItems: 'flex-end',
								gap: 2
							}}
						>
							<Box
								component="img"
								src={SoraMark}
								sx={{ height: '2.5rem' }}
							/>
							<Typography
								variant="h2Gascogne"
								sx={{ color: 'black' }}
							>
								{insightType}
							</Typography>
						</Box>
					}
				>
					{!insightHasLink && (
						<Typography variant="body2">
							{insightTextWithoutPrefix}
						</Typography>
					)}

					{insightHasLink && (
						<Typography variant="body2">
							{insightTextWithoutPrefix.slice(
								0,
								insightTextWithoutPrefix.length - 1
							)}{' '}
							<ExternalLink
								variant="body2"
								href={insightLink}
								target="_blank"
								rel="noreferrer"
								sx={{
									'&:visited': { color: 'purple' }
								}}
							>
								here
							</ExternalLink>
							.
						</Typography>
					)}

					{isSendNewLoanInquiryLoading && (
						<Loader boxSX={{ marginTop: 2 }} />
					)}

					{!isSendNewLoanInquiryLoading && (
						<TertiaryButton
							sx={{ marginTop: 4 }}
							onClick={() => {
								sendNewLoanInquiry.mutate(
									{
										insightRelatedWalletOverviewData,
										insightTextOutput
									},
									{
										onSuccess: () => {
											closeModal();
										}
									}
								);
							}}
						>
							Request more information
						</TertiaryButton>
					)}
				</Modal>
			</>
		);
	}
}

export default InsightsModal;
