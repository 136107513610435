import { Alert, Link as ExternalLink, Typography } from '@mui/material';

function FreemiumBanner() {
	return (
		<Alert
			icon={false}
			variant="filled"
			severity="warning"
			sx={{
				bgcolor: '#FFBC00',
				display: 'flex',
				justifyContent: 'center',
				borderRadius: '0'
			}}
		>
			<Typography variant="body2" sx={{ display: 'inline-block' }}>
				Click&nbsp;
			</Typography>
			<ExternalLink
				variant="body2"
				href="https://buy.stripe.com/aEU4hOe4R0GNdeo9AM"
				target="_blank"
				rel="noopener noreferrer"
				sx={{
					display: 'inline-block',
					color: 'black',
					textDecoration: 'underline',
					'&:visited': {
						color: 'initial'
					}
				}}
			>
				here
			</ExternalLink>
			<Typography variant="body2" sx={{ display: 'inline-block' }}>
				&nbsp;to upgrade your account for full access and to onboard
				more than 5 clients
			</Typography>
		</Alert>
	);
}

export default FreemiumBanner;
