import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print/lib/index';
import { Grid, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PropTypes from 'prop-types';

// Our components
import Alert from 'components/Alert';
import ExportableReport from 'components/ExportableReport/ExportableReport';
import Loader from 'components/Loader/index';
import { TertiaryButton, TextButton } from 'components/Button/Button';

// Our hooks
import useMutateContactClient from 'hooks/useMutateContactClient';
import useMutateRefreshSingleClient from 'hooks/dataRefresh/useMutateRefreshSingleClient';
import useGetBurnDownChart from 'hooks/Burdown/queries/useGetBurnDownChart';
import useGetWalletOverview from 'hooks/soraWallet/useGetWalletOverview';

// Our Utils
import getTimeAgo from 'shared/utils/date/getTimeAgo';
import getLastUpdatedTimeStamp from 'shared/utils/date/getLastUpdatedTimeStamp';

function SoraWalletHeading({ clientName, isClient }) {
	const contactSubmissionMutation = useMutateContactClient();
	const { isLoading, isSuccess } = useGetBurnDownChart();

	const refreshData = useMutateRefreshSingleClient();

	const { data: lastUpdatedTimeStamp } = useGetWalletOverview(
		getLastUpdatedTimeStamp
	);

	const exportableReportRef = useRef(null);

	const handlePrint = useReactToPrint({
		documentTitle: `${clientName}'s_Report`,
		content: () => exportableReportRef.current
	});

	const { isError: contactClientError, isSuccess: contactClientSuccess } =
		contactSubmissionMutation;

	const { isSuccess: isRefreshDataSuccess, isError: isRefreshDataError } =
		refreshData;

	const isAdvisor = !isClient;

	const timeAgo = refreshData?.data
		? getTimeAgo(refreshData?.data)
		: getTimeAgo(lastUpdatedTimeStamp);

	return (
		<Grid
			container
			sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
		>
			{isAdvisor && (contactClientSuccess || isRefreshDataSuccess) && (
				<Alert variant="success" />
			)}
			{isAdvisor && (contactClientError || isRefreshDataError) && (
				<Alert variant="error" />
			)}

			<Grid container item xs={9} xl={10} sx={{ alignItems: 'center' }}>
				<Typography
					variant="h1Gascogne"
					sx={{ margin: '8px 32px 0px 8px' }}
				>
					{clientName}&rsquo;s Wallet
				</Typography>
				{isAdvisor && isSuccess && (
					<TertiaryButton onClick={() => handlePrint()}>
						Insights Report
					</TertiaryButton>
				)}
			</Grid>

			<Grid item>
				<TextButton
					aria-label="refresh"
					disabled={refreshData.isLoading}
					onClick={() => refreshData.mutate({})}
					sx={{
						color: '#323232',
						textDecoration: 'none'
					}}
				>
					{refreshData.isLoading && (
						<RefreshIcon
							sx={{
								color: '#323232',
								marginRight: 1,
								'@keyframes rotateAnimation': {
									from: { transform: 'rotate(0deg)' },
									to: { transform: 'rotate(360deg)' }
								},
								animation: 'rotateAnimation 2s linear infinite'
							}}
						/>
					)}

					{!refreshData.isLoading && (
						<RefreshIcon
							sx={{
								color: '#323232',
								marginRight: 1
							}}
						/>
					)}

					<Typography
						variant="caption"
						sx={{ lineHeight: '1.5' }}
					>{`Data refreshed ${timeAgo} ago`}</Typography>
				</TextButton>
			</Grid>

			{isAdvisor && isLoading && <Loader />}
			{isSuccess && <ExportableReport ref={exportableReportRef} />}
		</Grid>
	);
}

SoraWalletHeading.propTypes = {
	clientName: PropTypes.string.isRequired,
	contactClientData: PropTypes.shape({
		name: PropTypes.string
	}).isRequired,
	isClient: PropTypes.bool.isRequired
};

export default SoraWalletHeading;
