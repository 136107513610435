import numbro from 'numbro'; // numbro package - https://numbrojs.com/

// Our Constants
import {
	DEFAULT_DECIMAL_ROUND,
	AUTO,
	BUSINESS,
	CASHOUT_OFFER_TYPE,
	CONSTRUCTION,
	COMMERCIAL,
	SBLOC,
	SBLINE,
	FARM,
	HELOC,
	LOT,
	MORTGAGE,
	NEW_LOAN_OFFER_TYPE,
	PERSONAL,
	REFINANCE_OFFER_TYPE,
	REVERSEMORTGAGE,
	STUDENT
} from 'shared/constants';

/**
 *
 * @param {string | number | boolean | null | undefined} data
 * @returns {boolean}
 */
export const dataIsValid = (data) => data !== undefined && data !== null;

export const trimNumber = (value) => {
	const trimmedValue = value.trim();
	const finalTrimming =
		trimmedValue[trimmedValue.length - 1] === '.'
			? trimmedValue.slice(0, trimmedValue.length - 1)
			: trimmedValue;
	return finalTrimming;
};

/**
 * @param {string}
 */
export const isNumber = (value) => {
	const trimmedNumber = trimNumber(value);
	const castedValue = Number(trimmedNumber);
	return !Number.isNaN(castedValue);
};

// This will format a number to currency in USD
// It will round the fraction units to 0 meaning no decimal point will be used.
// e.g. 45000 -> 45,000
export const dollarFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 0
});

// This function takes 2 things:
// 1 - The number being rounded
// 2 - To which decimal point you'd like the number rounded
// examples:
// roundToDecimal(945.876, 1) = 945.9
// roundToDecimal(945.876, 2) = 945.88
// roundToDecimal(945.876, 0) = 946

/**
 *
 * @param {number} number
 * @param {number} toDecimalPoint
 * @returns {number}
 */

export const roundToDecimal = (
	number,
	toDecimalPoint = DEFAULT_DECIMAL_ROUND
) => {
	const convertedNumber = numbro(number).format({
		thousandSeparated: true,
		mantissa: toDecimalPoint
	});

	if (Number.isNaN(convertedNumber)) {
		throw new Error(
			`${number} is not a number and therefore cannot be roundToDecimal`
		);
	}
	return convertedNumber;
};

export const isFormValueReady = (inputData) => inputData !== ''; // Returns false if value is empty
export const isSubmissionReady = (formData) => formData.every(isFormValueReady);
export const clearFormValues = (reactSetValueHooks) =>
	reactSetValueHooks.map((reactHook) => reactHook('')); // expects an array of react hook changes e.g. [setMessage, setFirstNameValue]

export const simpleValidEmail = (email) =>
	email.length > 0 && email.includes('.') && email.includes('@');

export const extractSoraScore = (soraScore) => {
	try {
		if (soraScore && soraScore.includes('_'))
			return soraScore.split('_')[2];
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e);
		return 'NA';
	}
	return soraScore;
};

export const getErrorMessage = (errorMessage, errorMessageHandler) => {
	try {
		const processedErrorMessage = errorMessage.split(' ');
		const isFireBaseError = processedErrorMessage[0] === 'Firebase:';

		// This if statement can be converted to a switch statement once we implement more error handling
		// currently if its a firebase error then we use errorMessageHandler to write a custom error message based on the error provided
		// we can implement the same thing for pinwheel
		// for Sora API errors we simply return the errorMessage and rener our back end teams error
		if (isFireBaseError) {
			const FirebaseErrorType =
				processedErrorMessage[processedErrorMessage.length - 1];
			return errorMessageHandler[processedErrorMessage[0]][
				FirebaseErrorType
			];
		}
		return errorMessage;
	} catch (e) {
		return 'something went wrong';
	}
};

// this fn will turn a number like 1000 into 1k
// this fn will turn 2051233904.2800293 into 2.05b
export const numbroAveragedToDecimal = (num, toDecimalPoint = 3) =>
	numbro(num).format({ average: true, totalLength: toDecimalPoint });

export const convertMonthsToYears = (
	months,
	roundedToDecimal = DEFAULT_DECIMAL_ROUND
) => roundToDecimal(months / 12, roundedToDecimal) ?? months;

export const isValidSSN = (ssn) => {
	// return early for most common case
	if (ssn === '000-00-000' || ssn === '00000000') return false;
	// Remove any non-digit characters
	const cleanedSSN = ssn.replace(/\D/g, '');

	// Check for the correct length
	if (cleanedSSN.length !== 9) {
		return false; // Invalid length
	}

	// Check if parts are valid numbers
	if (
		!/^\d{3}$/.test(cleanedSSN.substring(0, 3)) ||
		!/^\d{2}$/.test(cleanedSSN.substring(3, 5)) ||
		!/^\d{4}$/.test(cleanedSSN.substring(5, 9))
	) {
		return false; // Parts are not valid numbers
	}

	return true; // Valid SSN
};

// Function takes an interestRate, if it's 0, 0.0, '0' or '0.0' returns N/A
// Otherwise the rounded and formatted interestRate is returned like 8.49%
export const getFormattedLiabilityRate = (interestRate) => {
	const possibleZeroes = new Set([0, 0.0, '0', '0.0']);

	if (!dataIsValid(interestRate) || possibleZeroes.has(interestRate))
		return 'N/A';

	return `${roundToDecimal(interestRate)}%`;
};

// Function takes an insight string, pulls the URL out of it and returns the url
export const pullUrlFromInsight = (insight) => {
	if (insight.includes('https://')) {
		// Parse out the full url from in the insight text
		const indexOfUrlStart = insight.indexOf('[');
		const urlForLink = insight.substr(indexOfUrlStart + 1);
		const parsedUrlForLink = urlForLink.slice(0, -1);

		return parsedUrlForLink;
	}
	return insight;
};

// Function takes an insight string and removes the URL from the end of that string
export const removeLinkFromInsight = (insight) => {
	if (insight.includes('https://')) {
		// Parse out the full url from in the insight text
		const indexOfUrlStart = insight.indexOf('[');
		const insightWithoutLink = insight.substr(0, indexOfUrlStart - 1);

		return insightWithoutLink;
	}
	return insight;
};

export const removePrefixFromInsight = (insight) => {
	if (insight.includes(':')) {
		const indexOfPrefix = insight.indexOf(':');
		const insightWithoutPrefix = insight.substr(indexOfPrefix + 2);

		return insightWithoutPrefix;
	}

	return insight;
};

export const decodedLoanType = (tradelineType, offerType) => {
	if (offerType === 'Other' || tradelineType === 'OTHERS') return 'Other';
	if (offerType === NEW_LOAN_OFFER_TYPE) {
		if (tradelineType === AUTO) {
			return 'New Automotive Loan';
		}
		if (tradelineType === MORTGAGE) {
			return 'New Mortgage Loan';
		}
		if (tradelineType === CONSTRUCTION) {
			return 'Construction Loan';
		}
		if (tradelineType === PERSONAL) {
			return 'New Personal Loan';
		}
		if (tradelineType === FARM) {
			return 'New Farm Loan';
		}
		if (tradelineType === LOT) {
			return 'New Lot Loan';
		}
		if (tradelineType === BUSINESS) {
			return 'Commercial Loan';
		}
	} else if (offerType === REFINANCE_OFFER_TYPE) {
		if (tradelineType === AUTO) {
			return 'Automotive Refinance Loan';
		}
		if (tradelineType === PERSONAL) {
			return 'Personal Refinance Loan';
		}
		if (tradelineType === MORTGAGE) {
			return 'Mortgage Refinance Loan';
		}
		if (tradelineType === STUDENT) {
			return 'Student Refinance Loan';
		}
		if (tradelineType === REVERSEMORTGAGE) {
			return 'Reverse Mortgage Loan';
		}
		if (tradelineType === HELOC) {
			return 'HELOC Loan';
		}
	} else if (offerType === CASHOUT_OFFER_TYPE) {
		if (tradelineType === MORTGAGE) {
			return 'Cash Out Refinance Loan';
		}
	} else if (tradelineType === SBLOC || tradelineType === SBLINE) {
		return 'SBLOC';
	}
	return 'unknown';
};
