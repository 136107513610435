// Our Utils
import sortLiabilitiesByKey from 'shared/utils/walletOverview/sortByKey';

// our constants
import { AUTO, CREDITCARD, HELOC, MORTGAGE, STUDENT } from 'shared/constants';

const defaultLiabilitiesSortOrder = (totalLiabilitiesData) => {
	const liabilitySet = new Set([AUTO, CREDITCARD, HELOC, MORTGAGE, STUDENT]);

	const mortgageLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === MORTGAGE
	);

	const helocLibailities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === HELOC
	);

	const autoLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === AUTO
	);

	const studentLoanLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === STUDENT
	);

	const creditCardLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => tradeLineType === CREDITCARD
	);

	const otherLiabilities = totalLiabilitiesData.filter(
		({ tradeLineType }) => !liabilitySet.has(tradeLineType)
	);

	const sortedMortgages = sortLiabilitiesByKey(
		mortgageLiabilities,
		'outstandingBalance',
		true
	);

	const sortedHelocs = sortLiabilitiesByKey(
		helocLibailities,
		'outstandingBalance',
		true
	);

	const sortedAutoLoans = sortLiabilitiesByKey(
		autoLiabilities,
		'outstandingBalance',
		true
	);

	const sortedStudentLoans = sortLiabilitiesByKey(
		studentLoanLiabilities,
		'outstandingBalance',
		true
	);

	const sortedCreditCards = sortLiabilitiesByKey(
		creditCardLiabilities,
		'outstandingBalance',
		true
	);

	const sortedOtherLiabilities = sortLiabilitiesByKey(
		otherLiabilities,
		'outstandingBalance',
		true
	);

	return [
		...sortedMortgages,
		...sortedHelocs,
		...sortedAutoLoans,
		...sortedStudentLoans,
		...sortedCreditCards,
		...sortedOtherLiabilities
	];
};

export default defaultLiabilitiesSortOrder;
