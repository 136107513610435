import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

// Our Axios Instance
import axiosInstance from 'services/API/API';

// Our Endpoints
import { refinanceOfferUrl } from 'shared/api-urls';

// Our Query key
import { REFINANCE_OFFERS } from 'shared/query-keys';

function useGetNewRefinanceOffers() {
	const location = useLocation();

	const { state } = location;

	const { tradelineId } = state;

	return useQuery(
		[REFINANCE_OFFERS, tradelineId],
		async () => {
			const res = await axiosInstance.get(
				`${refinanceOfferUrl}/${tradelineId}`
			);

			return res.data.data;
		},
		{
			refetchOnWindowFocus: false,
			select: (offerData) => {
				const isDataPresent = Array.isArray(offerData);
				const uniqueLenders = new Set();
				const uniqueOffers = offerData.filter((offer) => {
					const isUnique = !uniqueLenders.has(offer.lenderId);
					uniqueLenders.add(offer.lenderId);
					return isUnique;
				});

				const isTooManyOffers =
					isDataPresent && uniqueOffers.length > 3;

				if (isTooManyOffers) {
					return uniqueOffers.slice(0, 3);
				}

				return uniqueOffers;
			}
		}
	);
}

export default useGetNewRefinanceOffers;
