import { Helmet } from 'react-helmet-async';
import { Routes, Route, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import useMediaQuery from '@mui/material/useMediaQuery';

// Important Routing Logic
import ProtectedRoute from 'components/ProtectedRouter/ProtectedRoute';

// Frames
import ContainerFrameWithNav from 'components/Frame/ContainerFrameWithNav';
import ContainerFrameOnboarding from 'components/Frame/ContainerFrameOnboarding';

// App Related
import ContactUs from 'pages/ContactUs/ContactUsPage';
import Error404Page from 'pages/Error/404';
import Settings from 'pages/Settings/SettingsPage';
// import MaintenancePage from 'pages/Maintenance/MaintenancePage';
import SuperAdminPage from 'pages/Admin/SuperAdminPage';
import UnsubscribePage from 'pages/Email/UnsubscribePage';

// Login Related
import ForgotPasswordPage from 'pages/ForgotPassword/ForgotPasswordPage';
import LoginPage from 'pages/Login/LoginPage';
import RoutingUser from 'components/Signup/RoutingUser';
import SignupPage from 'pages/Signup/SignupPage';

// Magic Token Related
import MagicTokenVerification from 'pages/Login/MagicTokenVerification';

// DTS magic link handloing
import DtaMagicRoutingUser from 'pages/RoutingPages/DtaMagicRoutingUser';

// Client Invite
import AddingClients from 'pages/Client/AddingClient/AddingClientsPage';
import ClientInviteThanks from 'pages/Client/AddingClient/AddClientThankYou/ClientAddedThanks';
import TriggerClientInvitation from 'pages/Client/Entry/TriggerClientInvitePage';
import VerifyClient from 'components/Client/Onboarding/VerifyClientInvite';
import VerifyEmployee from 'components/Client/Onboarding/Employee/VerifyEmployee';

// FA Onboarding
import FaAdditionalDetails from 'components/Signup/FaAdditionalDetails';
import Thanks from 'pages/Signup/FaThanksPage';

// FA Dashboard
import AnalyticsIntroPage from 'pages/Analytics/AnalyticsIntroPage';
import Analytics from 'pages/Analytics/Analytics';
import ClientReports from 'pages/Reports/ClientReports';
import FADashboard from 'pages/FADashboard/FADashboard';
import MessagesPage from 'pages/Messages/MessagesPage';
import ProspectiveClientPage from 'pages/ProspectiveClientPage/ProspectiveClientPage';

// Advisor Integrations
import AdvisorIntegrationsPage from 'pages/Integrations/AdvisorIntegrationsPage';

// Loan Pipeline
import LoanPipeline from 'pages/LoanPipeline/LoanPipeline';

// Sora Wallet
import SoraWallet from 'pages/SoraWallet/SoraWalletPage';
import SoraWalletOverview from 'pages/SoraWalletOverview/SoraWalletOverview';
import SoraWalletWrapper from 'components/SoraWallet/SoraWalletWrapper';

// Offers Page
import OffersPage from 'pages/Offers/OffersPage';

/* Client Onboarding */
import ClientAdditionalDetails from 'pages/Client/Onboarding/AdditionalDetailsPage';
import UploadLoanDocs from 'pages/Client/Onboarding/UploadLoanDocs';
import UnableToVerify from 'pages/Client/Onboarding/UnableToVerify';

// Multiple Liabilities
import NewLoanSelection from 'pages/NewLoan/NewLoanSelection';
import NewMortgageLiability from 'pages/NewLoan/NewMortgageLiability';
import NewAutoLiability from 'pages/NewLoan/NewAutoLiability';
import AutoLiability from 'pages/Client/Onboarding/Liabilities/AutoLiability';
import CreditCardLiability from 'pages/Client/Onboarding/Liabilities/CreditCardLiability';
import CommercialLiability from 'pages/Client/Onboarding/Liabilities/CommercialLiability';
import OtherLiabilities from 'pages/Client/Onboarding/Liabilities/OtherLiabilities';
import HelocLiability from 'pages/Client/Onboarding/Liabilities/HelocLiability';
import HomeLiability from 'pages/Client/Onboarding/Liabilities/HomeLiability';
import LoanSelection from 'pages/Client/Onboarding/Liabilities/LoanSelection';
import LiabilitiesList from 'pages/Client/Onboarding/Liabilities/LiabilitiesList';
import PersonalLiability from 'pages/Client/Onboarding/Liabilities/PersonalLiability';
import StudentLiability from 'pages/Client/Onboarding/Liabilities/StudentLiability';

// Onboarding Income
import IncomeInfo from 'pages/Client/Onboarding/Income/IncomeInfo';

// Onboarding Assets
import AssetsForm from 'pages/Client/Onboarding/Assets/ClientAssetsPage';
import OnboardingReviewForm from 'pages/Client/Onboarding/Review/OnboardingReview';

// Loan Inquires
import LoanRequestDetails from 'pages/NewLoan/LoanRequestDetailsPage';

// Price a Loan
import PriceALoanPage from 'pages/PriceALoan/PriceALoan';

// Client New Loan
import AddManualLoan from 'pages/Client/AddManualLoan/AddManualLoan';
import HomeLoanQuestionnaire from 'components/Forms/ManualLiability/HomeLoanQuestionnaire';
import AutoLoanQuestionnaire from 'components/Forms/ManualLiability/AutoLoanQuestionnaire';

// Third Party
import WealthBoxConsumePage from 'pages/Integrations/WealthBoxConsumePage';

// DTA
import DebtToleranceAssessment from 'pages/DebtToleranceAssessment/DebtToleranceAssessment';

// Design Page
import DesignPage from 'pages/Design/DesignPage';

// Routes
import {
	ADVISOR_ANALYTICS,
	ADVISOR_ANALYTICS_INTRO,
	ADVISOR_NEW_LOAN_SELECTION_ROUTE,
	ADVISOR_ROUTE,
	ADVISOR_INTEGRATIONS_ROUTE,
	CASH_OUT_ROUTE,
	CLIENT_NEW_LOAN_SELECTION_ROUTE,
	CLIENT_ONBOARDING_ADDITIONAL_DETAILS_ROUTE,
	CLIENT_ONBOARDING_ASSETS_ROUTE,
	CLIENT_ONBOARDING_INCOME_ROUTE,
	CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE,
	CLIENT_ONBOARDING_PARTIAL_ROUTE,
	CLIENT_ONBOARDING_REVIEW_ROUTE,
	CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE,
	CLIENT_ONBOARDING_UPLOAD_DOCS_ROUTE,
	CLIENT_SORAWALLET_ROUTE,
	CONTACT_US_ROUTE,
	DASHBOARD_ROUTE,
	ERROR_ROUTE,
	FA_ONBOARDING_ADDITIONAL_DETAILS_ROUTE,
	FA_ONBOARDING_THANKS_ROUTE,
	FORGOT_PASSWORD_ROUTE,
	INVITE_ROUTE,
	INVITE_THANKS_ROUTE,
	LIABILITY_ROUTE,
	LOAN_INQUIRY_DETAILS_ROUTE,
	LOAN_INQUIRY_ROUTE,
	LOGIN_ROUTE,
	MESSAGES_ROUTE,
	NEW_LOAN_AUTO_ROUTE,
	NEW_LOAN_HOME_ROUTE,
	NEW_LOAN_ROUTE,
	PROSPECTIVE_CLIENT_ROUTE,
	REFINANCE_ROUTE,
	REPORTS_ROUTE,
	ROOT_ROUTE,
	ROUTING_USER_ROUTE,
	SELECT_NEW_LOAN_ROUTE,
	SETTINGS_ROUTE,
	SIGNUP_ROUTE,
	SORA_FOR_EVERYBODY_ROUTE,
	SUPER_ADMIN_ROUTE,
	UNSUBSCRIBE_ROUTE,
	VARIABLE_ADVISOR_TRADELINE_ROUTE,
	VARIABLE_CLIENT_ID_ROUTE,
	VARIABLE_TRADELINE_ROUTE,
	WEALTH_BOX_REDIRECT_URI,
	DTA_ROUTE,
	DTS_ROUTING_USER
} from 'routes/index';

// TODO:
// After company firebase account is setup, go to google analytics and connect the firebase
// property to google analytics, replace the initialize code below
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-000000-01');
// ReactGA.pageview(window.location.pathname + window.location.search);

// CONSTANTS
const isRunningLocally = process.env.NODE_ENV === 'development'; // process.env.NODE_EV will be development if project is being run as local host.
const LATEST_GIT_VERSION = process.env.REACT_APP_GIT_VERSION;
const QUERY_CACHE_TIME = 1000 * 60 * 10; // 1000 ms * 60s * 10 => 10 minutes

function App() {
	// Changes default retry for both queries and mutations to 0 so a SINGLE request is made for now.
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				retry: 0,
				cacheTime: QUERY_CACHE_TIME
			},
			mutations: {
				retry: 0,
				cacheTime: QUERY_CACHE_TIME
			}
		}
	});

	// Mobile view media query, to be used to style mobile versions of pages
	// Pass this into whatever component you need to
	const mobileView = useMediaQuery((SoraTheme) =>
		SoraTheme.breakpoints.down('sm')
	);

	// const hasErrorMsg = queryClient.getQueryData(ERROR_MESSAGE_DATA);
	// const hasSuccessMsg = queryClient.getQueryData(SUCCESS_MESSAGE_DATA);

	return (
		<div className="App">
			<Helmet>
				<title>Sora Finance</title>
			</Helmet>
			<QueryClientProvider client={queryClient}>
				<Routes>
					{/* Temporary Design Page */}
					<Route path="/design" element={<DesignPage />} />
					{/* Highest level routes */}
					<Route path={ERROR_ROUTE} element={<Error404Page />} />
					<Route
						path={ROOT_ROUTE}
						element={<Navigate to={LOGIN_ROUTE} />}
					/>

					{/* Pages without an container frame */}
					<Route
						path={FORGOT_PASSWORD_ROUTE}
						element={<ForgotPasswordPage />}
					/>
					<Route path={LOGIN_ROUTE} element={<LoginPage />} />

					{/* Expected Routing User */}
					<Route
						path={ROUTING_USER_ROUTE}
						element={<RoutingUser />}
					/>

					{/* dts magic link handling */}
					<Route
						path={DTS_ROUTING_USER}
						element={<DtaMagicRoutingUser />}
					/>
					<Route path={SIGNUP_ROUTE} element={<SignupPage />} />
					<Route
						path={SUPER_ADMIN_ROUTE}
						element={<SuperAdminPage />}
					/>
					<Route
						path={UNSUBSCRIBE_ROUTE}
						element={<UnsubscribePage />}
					/>

					{/* Acceptance of a client invitation */}
					<Route path="clientVerify">
						<Route path=":inviteCode" element={<VerifyClient />} />
					</Route>
					<Route path="employeeVerify">
						<Route
							path=":employeeData"
							element={<VerifyEmployee />}
						/>
					</Route>

					<Route
						path={SORA_FOR_EVERYBODY_ROUTE}
						element={<TriggerClientInvitation />}
					/>

					{/* Dashboard version */}
					<Route
						element={
							<ProtectedRoute
								element={
									<ContainerFrameWithNav
										checkBanner
										sx={{
											background:
												'linear-gradient(180deg, #EBF3F9 0%, #FFF 100%), #FFF',
											paddingLeft: 0,
											paddingRight: 0
										}}
										outletSX={{
											paddingRight: 0
										}}
									/>
								}
							/>
						}
					>
						<Route
							path={DASHBOARD_ROUTE}
							element={<FADashboard />}
						/>
					</Route>

					{/* Wallet Overview */}
					<Route
						element={
							<ContainerFrameWithNav
								disableFooter
								sx={{
									paddingLeft: 0,
									paddingRight: 0
								}}
								outletSX={{
									paddingRight: 0
								}}
							/>
						}
					>
						<Route path={CLIENT_SORAWALLET_ROUTE}>
							<Route
								element={
									<ProtectedRoute
										element={<SoraWalletWrapper />}
									/>
								}
							>
								<Route
									index
									element={<SoraWalletOverview isClient />}
								/>
								{/* Route for the advisor view of a specific tradeline */}
								<Route path={LIABILITY_ROUTE}>
									<Route
										path={VARIABLE_TRADELINE_ROUTE}
										element={<SoraWallet isClient />}
									/>
								</Route>

								<Route path={ADVISOR_ROUTE}>
									<Route path={VARIABLE_CLIENT_ID_ROUTE}>
										<Route
											index
											element={
												<SoraWalletOverview
													isClient={false}
												/>
											}
										/>
										<Route
											path={
												VARIABLE_ADVISOR_TRADELINE_ROUTE
											}
											element={
												<SoraWallet isClient={false} />
											}
										/>
									</Route>
								</Route>
							</Route>
						</Route>
					</Route>

					<Route
						element={
							<ContainerFrameWithNav
								sx={{
									background:
										'linear-gradient(180deg, #EBF3F9 0%, #FFF 100%), #FFF'
								}}
							/>
						}
					>
						{/* dts */}
						<Route
							path={DTA_ROUTE}
							element={
								<ProtectedRoute
									element={
										<DebtToleranceAssessment isClient />
									}
								/>
							}
						/>

						<Route
							path={ADVISOR_ANALYTICS_INTRO}
							element={
								<ProtectedRoute
									element={<AnalyticsIntroPage />}
								/>
							}
						/>

						<Route
							path={ADVISOR_ANALYTICS}
							element={<ProtectedRoute element={<Analytics />} />}
						/>
					</Route>

					{/* Mobile Friendly Nav Frame */}
					<Route element={<ContainerFrameWithNav />}>
						<Route
							path={WEALTH_BOX_REDIRECT_URI}
							element={
								<ProtectedRoute
									element={<WealthBoxConsumePage />}
								/>
							}
						/>

						{/* Client Routes for adding new liability */}
						{/* add-new */}
						<Route
							path={CLIENT_NEW_LOAN_SELECTION_ROUTE}
							element={<NewLoanSelection />}
						>
							{/* Outlets for additional */}
							<Route path="auto" element={<NewAutoLiability />} />
							<Route
								path="mortgage"
								element={<NewMortgageLiability />}
							/>
							<Route
								path="student"
								element={<StudentLiability />}
							/>
							<Route
								path="personal"
								element={<PersonalLiability />}
							/>
							<Route
								path="creditcard"
								element={<CreditCardLiability />}
							/>
							<Route
								path="commercial"
								element={<CommercialLiability />}
							/>
						</Route>

						{/* Advisor Routes for adding a new liability */}
						<Route
							path={ADVISOR_NEW_LOAN_SELECTION_ROUTE}
							element={<NewLoanSelection />}
						>
							{/* Outlets for additional */}
							<Route path="auto" element={<NewAutoLiability />} />
							<Route
								path="mortgage"
								element={<NewMortgageLiability />}
							/>
							{/* new */}
							<Route
								path="mortgages"
								element={<HomeLiability />}
							/>
							<Route
								path="student"
								element={<StudentLiability />}
							/>
							<Route
								path="personal"
								element={<PersonalLiability />}
							/>
							<Route
								path="creditcard"
								element={<CreditCardLiability />}
							/>
							<Route
								path="commercial"
								element={<CommercialLiability />}
							/>
						</Route>

						<Route path="update">
							{/* Client update routes */}
							<Route
								path="auto/:tradeLineId"
								element={<AutoLiability />}
							/>
							<Route
								path="mortgage/:tradeLineId"
								element={<HomeLiability />}
							/>
							<Route
								path="student/:tradeLineId"
								element={<StudentLiability />}
							/>
							<Route
								path="personal/:tradeLineId"
								element={<PersonalLiability />}
							/>

							<Route
								path="creditcard/:tradeLineId"
								element={<CreditCardLiability />}
							/>
							<Route
								path="heloc/:tradeLineId"
								element={<HelocLiability />}
							/>

							<Route
								path="commercial/:tradeLineId"
								element={
									<CommercialLiability enablePrePopulation />
								}
							/>

							{/* Advisor update routes */}
							<Route path={VARIABLE_CLIENT_ID_ROUTE}>
								<Route
									path="auto/:tradeLineId"
									element={<AutoLiability />}
								/>
								<Route
									path="mortgage/:tradeLineId"
									element={<HomeLiability />}
								/>
								<Route
									path="student/:tradeLineId"
									element={<StudentLiability />}
								/>
								<Route
									path="personal/:tradeLineId"
									element={<PersonalLiability />}
								/>

								<Route
									path="creditcard/:tradeLineId"
									element={<CreditCardLiability />}
								/>
								<Route
									path="heloc/:tradeLineId"
									element={<HelocLiability />}
								/>
								<Route
									path="commercial/:tradeLineId"
									element={
										<CommercialLiability
											enablePrePopulation
										/>
									}
								/>
							</Route>
						</Route>
						<Route
							path={LOAN_INQUIRY_ROUTE}
							element={
								<ProtectedRoute element={<PriceALoanPage />} />
							}
						/>

						<Route
							path={LOAN_INQUIRY_DETAILS_ROUTE}
							element={
								<LoanRequestDetails mobileView={mobileView} />
							}
						/>

						<Route
							path={SETTINGS_ROUTE}
							element={<ProtectedRoute element={<Settings />} />}
						/>

						<Route
							path={REPORTS_ROUTE}
							element={<ClientReports />}
						/>

						<Route
							path={MESSAGES_ROUTE}
							element={
								<ProtectedRoute element={<MessagesPage />} />
							}
						/>

						<Route
							path={CONTACT_US_ROUTE}
							element={<ProtectedRoute element={<ContactUs />} />}
						/>

						<Route
							path={PROSPECTIVE_CLIENT_ROUTE}
							element={
								<ProtectedRoute
									element={<ProspectiveClientPage />}
								/>
							}
						/>

						<Route
							path={ADVISOR_INTEGRATIONS_ROUTE}
							element={
								<ProtectedRoute
									element={<AdvisorIntegrationsPage />}
								/>
							}
						/>

						<Route
							path="/loan-pipeline"
							element={
								<ProtectedRoute element={<LoanPipeline />} />
							}
						/>

						{/* Advisor Adding CLients Page */}
						<Route
							path={INVITE_ROUTE}
							element={<AddingClients />}
						/>

						<Route
							path={INVITE_THANKS_ROUTE}
							element={<ClientInviteThanks />}
						/>

						<Route
							path={SELECT_NEW_LOAN_ROUTE}
							element={<AddManualLoan />}
						/>

						{/* Client select new loan */}
						{/* These Routes are for filing a new loan NOT adding a liability. */}

						<Route
							path={NEW_LOAN_HOME_ROUTE}
							element={<HomeLoanQuestionnaire />}
						/>
						<Route
							path={NEW_LOAN_AUTO_ROUTE}
							element={<AutoLoanQuestionnaire />}
						/>

						{/* Offers Related Routes */}
						<Route path="offers">
							<Route
								path={NEW_LOAN_ROUTE}
								element={<OffersPage OfferType="new_loan" />}
							/>
							<Route
								path={CASH_OUT_ROUTE}
								element={<OffersPage OfferType="cash_out" />}
							/>
							<Route
								path={REFINANCE_ROUTE}
								element={<OffersPage OfferType="refinance" />}
							/>
						</Route>
					</Route>

					{/* Mobile Friendly Onboarding Frame */}
					{/* TODO: Progress needs to be passed through here */}
					<Route element={<ContainerFrameOnboarding />}>
						{/* Client Onboarding */}
						<Route
							path={
								CLIENT_ONBOARDING_LIABILITIES_IDENTIFIED_ROUTE
							}
							element={<LiabilitiesList />}
						/>

						<Route
							path={CLIENT_ONBOARDING_INCOME_ROUTE}
							element={<IncomeInfo />}
						/>

						<Route
							path={CLIENT_ONBOARDING_ASSETS_ROUTE}
							element={<AssetsForm />}
						/>

						<Route
							path={CLIENT_ONBOARDING_REVIEW_ROUTE}
							element={<OnboardingReviewForm />}
						/>

						<Route
							path={CLIENT_ONBOARDING_ADDITIONAL_DETAILS_ROUTE}
							element={<ClientAdditionalDetails />}
						/>

						<Route
							path={CLIENT_ONBOARDING_UNABLE_TO_VERIFY_ROUTE}
							element={<UnableToVerify />}
						/>

						<Route
							path={CLIENT_ONBOARDING_UPLOAD_DOCS_ROUTE}
							element={<UploadLoanDocs />}
						/>

						<Route path={CLIENT_ONBOARDING_PARTIAL_ROUTE}>
							{/* Dynamic Ones */}
							<Route
								path="auto/:tradeLineId"
								element={<AutoLiability />}
							/>
							<Route
								path="mortgage/:tradeLineId"
								element={<HomeLiability />}
							/>
							<Route
								path="student/:tradeLineId"
								element={<StudentLiability />}
							/>
							<Route
								path="personal/:tradeLineId"
								element={<PersonalLiability />}
							/>

							<Route
								path="creditcard/:tradeLineId"
								element={<CreditCardLiability />}
							/>
							<Route
								path="heloc/:tradeLineId"
								element={<HelocLiability />}
							/>

							{/* Static Routes */}
							<Route
								path="other"
								element={<OtherLiabilities />}
							/>
							<Route
								path="additional"
								element={<LoanSelection />}
							>
								{/* Outlets for additional */}
								<Route
									path="auto"
									element={<AutoLiability />}
								/>
								<Route
									path="mortgage"
									element={<HomeLiability />}
								/>
								<Route
									path="student"
									element={<StudentLiability />}
								/>
								<Route
									path="personal"
									element={<PersonalLiability />}
								/>
								<Route
									path="creditcard"
									element={<CreditCardLiability />}
								/>
								<Route
									path="commercial"
									element={<CommercialLiability />}
								/>
							</Route>
						</Route>

						{/* Financial Advisor Onboarding */}
						<Route
							path={FA_ONBOARDING_ADDITIONAL_DETAILS_ROUTE}
							element={<FaAdditionalDetails />}
						/>

						<Route
							path={FA_ONBOARDING_THANKS_ROUTE}
							element={<Thanks />}
						/>
					</Route>

					<Route
						element={<MagicTokenVerification />}
						path="verify/magic-link/:code"
					/>

					{/* RELEASE RELATED:
						For now, this will get uncommented when a release of the back end happens. We'll comment out all other routes and uncomment the maintenance routes.
						Once release is complete, we'll flip this back. */}

					{/* <Route path="maintenance" element={<MaintenancePage />} />
					<Route path="/" element={<Navigate to="/maintenance" />} />
					<Route path="*" element={<MaintenancePage />} /> */}
				</Routes>
				{isRunningLocally && (
					<>
						<p
							style={{
								position: 'absolute',
								top: 5,
								right: 80,
								padding: 2
							}}
						>
							Build Version:{' '}
							{LATEST_GIT_VERSION &&
								LATEST_GIT_VERSION.slice(0, 8)}
						</p>
						<ReactQueryDevtools initialIsOpen={false} />
					</>
				)}
			</QueryClientProvider>
		</div>
	);
}

export default App;
