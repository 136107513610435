import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { useQueryClient } from 'react-query';

// Our components
import Alert from 'components/Alert';
import { PrimaryButton } from 'components/Button/Button';
import CurrencyTextInput from 'components/Input/CurrencyTextInput';
import GetFullAddressPartialForm from 'components/Forms/addressRelated/GetFullAddressPartialForm';
import Input from 'components/Input/TextInput';
import Loader from 'components/Loader';
import LoanInquirySuccessModal from 'components/Modal/LoanInquiryConfirmationModal';
import LoanTypeDropdown from 'components/Client/Onboarding/LoanTypeDropdown';
import Dropdown from 'components/Dropdown/Dropdown';
import SoraTextField from 'components/Input/SoraTextField';
import RequiredFieldsTooltip from 'components/Forms/NewLoanForms/RequiredFieldsTooltip';
import { NUMBER, TEXT } from 'components/Input/Types';

// Our hooks 🪝
import useMutateCreateDeal from 'hooks/hubspot/useMutateCreateDeal';
import useMutateSaveNewLoanInquiry from 'hooks/newLoanInquiries/useMutateSaveNewLoanInquiry';
import useMutateUpdateLoanInquiry from 'hooks/newLoanInquiries/useMutateUpdatedLoanInquiry';
import useMutateSendGenericEmail from 'hooks/emails/useMutateSendGenericEmail';

// Our Query keys
import { ERROR_MESSAGE_DATA } from 'shared/query-keys';

// Our Utils
import { clearFormValues } from 'shared/utils';
import createDefaultCloseDate from 'shared/utils/hubspot/createDefaultCloseDate';
import formatCollection from 'shared/utils/formatting/currency/formatCollection';

// Constants

import {
	TYPES_OF_HOME_PROPERTIES,
	CONSTRUCTION,
	NEW_LOAN_OFFER_TYPE
} from 'shared/constants';

function ConstructionForm({
	isClientNameProvided,
	advisorEmail,
	advisorName,
	advisorCompany,
	advisorWebsite,
	additionalNotesValue,
	addressLine1Value,
	addressLine2Value,
	annualIncome,
	cityValue,
	clientId,
	creditScoreValue,
	desiredLoanTermValue,
	disableAutoComplete,
	downPaymentPercentValue,
	emailAddressValue,
	firstName,
	lastName,
	loanAmountValue,
	loanRequestId,
	loanTypeValue,
	postSuccessEvent,
	primaryUseValue,
	propertyTypeValue,
	setDisableAutoComplete,
	stateValue,
	zipCodeValue,
	loanType
}) {
	const queryClient = useQueryClient();

	const updateLoanInquiry = useMutateUpdateLoanInquiry();

	// API Calls
	const saveLoanInquiry = useMutateSaveNewLoanInquiry();
	const { isLoading: savingLoanInquiry } = saveLoanInquiry;

	// Mutations
	const sendGenericEmail = useMutateSendGenericEmail();
	const createDeal = useMutateCreateDeal();

	const fullNameValue = `${firstName} ${lastName}`;

	const [creditScore, setCreditScore] = useState(creditScoreValue || '');
	const [totalAnnualIncome, setTotalAnnualIncome] = useState(
		annualIncome || 0
	);

	// Address related fields
	const [addressLine1, setAddressLine1] = useState(addressLine1Value || '');
	const [addressLine2, setAddressLine2] = useState(addressLine2Value || '');
	const [city, setCity] = useState(cityValue || '');
	const [state, setState] = useState(stateValue || '');
	const [zipCode, setZipCode] = useState(zipCodeValue || '');
	const [loanAmount, setLoanAmount] = useState(loanAmountValue || '');
	const [downPaymentPercent, setDownPaymentPercent] = useState(
		downPaymentPercentValue || ''
	);
	const [propertyType, setPropertyType] = useState(propertyTypeValue || '');
	const [primaryUse, setPrimaryUse] = useState(primaryUseValue || '');
	const [desiredLoanTerm, setDesiredLoanTerm] = useState(
		desiredLoanTermValue || ''
	);
	const [selectedLoanType, setSelectedLoanType] = useState(
		loanTypeValue || ''
	);

	const [emailAddress, setEmailAddress] = useState(emailAddressValue || '');
	const [message, setMessage] = useState('');
	const [additionalNotes, setAdditionalNotes] = useState(
		additionalNotesValue || ''
	);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const isFormReady = useMemo(
		() => isClientNameProvided,
		[isClientNameProvided]
	);

	useEffect(
		() =>
			setMessage(`
				<div style="text-align: left;">
					<p>The advisor ${advisorName} has request a Construction Loan for one of their clients.</p>
					
					<div style="border: 1px solid black; padding: 10px; margin-bottom: 10px;">	
						<p>Advisor making request:</p>
						<p>Advisor's name: ${advisorName}</p>
						<p>Advisor's email: ${advisorEmail}</p>
					</div>
			
					<div style="border: 1px solid black; padding: 10px;">
						<p>They made a request for Construction Loan for the following CLIENT:</p>
						<p>Client's Email address: ${emailAddress}</p>
						<p>Client's Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}</p>
						<p>Total Income of all people applying: ${totalAnnualIncome}</p>
						<p>Loan amount: ${loanAmount}</p>
						<p>Down payment percent: ${downPaymentPercent}</p>
						<p>Property type: ${propertyType}</p>
						<p>Desired loan term: ${desiredLoanTerm}</p>
						<p>Desired loan type: ${selectedLoanType}</p>
					</div>

					<div style="border: 1px solid black; padding: 10px;">
						<p>Additional Notes:</p>


						${additionalNotes}
					</div>
				</div>
		`),
		[
			emailAddress,
			totalAnnualIncome,
			loanAmount,
			downPaymentPercent,
			propertyType,
			desiredLoanTerm,
			selectedLoanType,
			creditScore,
			addressLine1,
			addressLine2,
			city,
			state,
			zipCode,
			additionalNotes
		]
	);

	const sendFormData = () => {
		const sendToArray = ['lending@sorafinance.com'];
		const subject = `Construction Loan Request - ${firstName} ${lastName}`;
		sendGenericEmail.mutate(
			{ firstName, lastName, subject, message, sendToArray },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setCreditScore,
						setDesiredLoanTerm,
						setDownPaymentPercent,
						setEmailAddress,
						setLoanAmount,
						setSelectedLoanType,
						setPrimaryUse,
						setPropertyType,
						setState,
						setTotalAnnualIncome,
						setZipCode
					]);
				}
			}
		);
	};

	const submitNewLoanInquiry = () => {
		const newLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			primaryUse,
			city,
			clientId,
			creditScore,
			desiredLoanTerm,
			downPaymentPercent,
			emailAddress,
			firstName,
			lastName,
			loanAmount,
			selectedLoanType,
			offerType: NEW_LOAN_OFFER_TYPE,
			propertyType,
			state,
			totalAnnualIncome,
			tradelineType: CONSTRUCTION,
			zipCode
		};

		// Call mutation to update user's data
		saveLoanInquiry.mutate(newLoanInquiryData, {
			onSuccess: () => {
				setIsSuccessModalOpen(true);
			},
			onError: () => {
				// Set error message
				queryClient.setQueryData(
					ERROR_MESSAGE_DATA,
					'There was an error making your loan request.'
				);
			}
		});
	};

	const updateLoanRequest = () => {
		const updatedLoanInquiryData = {
			additionalNotes,
			addressLine1,
			addressLine2,
			primaryUse,
			city,
			clientId,
			creditScore,
			desiredLoanTerm,
			downPaymentPercent,
			emailAddress,
			firstName,
			lastName,
			loanAmount,
			selectedLoanType,
			offerType: NEW_LOAN_OFFER_TYPE,
			propertyType,
			state,
			totalAnnualIncome,
			tradelineType: CONSTRUCTION,
			zipCode
		};

		// Call mutation to update user's data
		updateLoanInquiry.mutate(
			{ loanRequestId, updatedLoanInquiryData },
			{
				onSuccess: () => {
					clearFormValues([
						setAdditionalNotes,
						setAddressLine1,
						setAddressLine2,
						setCity,
						setCreditScore,
						setDesiredLoanTerm,
						setDownPaymentPercent,
						setEmailAddress,
						setLoanAmount,
						setSelectedLoanType,
						setPrimaryUse,
						setPropertyType,
						setState,
						setTotalAnnualIncome,
						setZipCode
					]);

					if (postSuccessEvent) {
						postSuccessEvent();
					}
				},
				onError: () => {
					// Set error message
					queryClient.setQueryData(
						ERROR_MESSAGE_DATA,
						'There was an error making your loan request.'
					);
				}
			}
		);
	};

	const { isLoading } = sendFormData;

	const currentURL = window.location.href;
	const atLoanRequestDetailsUrl = currentURL.includes('loan-request-details');

	const submitForm = (event) => {
		event.preventDefault();

		const closeDate = createDefaultCloseDate();
		const valuesToFormat = [
			{
				type: 'currency',
				value: totalAnnualIncome,
				label: 'Annual Income:'
			},
			{
				type: 'currency',
				value: loanAmount,
				label: 'Loan amount:'
			},
			{
				type: 'percent',
				value: downPaymentPercent,
				label: 'Down payment %:'
			}
		];

		const formattedCollection = formatCollection(valuesToFormat);
		const advisorNotes = `Advisor's name: ${advisorName}
				Advisor's email: ${advisorEmail}
				Advisor's company: ${advisorCompany}
				Credit Score: ${creditScore}
				${formattedCollection}
				Property Type: ${propertyType}
				Primary Use: ${primaryUse}
				Desired Loan Term: ${desiredLoanTerm}
				Desired Loan Type: ${selectedLoanType}
				Address: ${addressLine1} ${addressLine2} ${city}, ${state} ${zipCode}
				------------------------------------------------------------------------------------------------
				Advisor's notes: ${additionalNotes}`;

		createDeal.mutate({
			advisorInfo: {
				email: advisorEmail,
				companyName: advisorCompany,
				companyDomain: advisorWebsite
			},
			properties: {
				amount: loanAmount,
				dealname: `${advisorName} / ${fullNameValue}`,
				dealtype: loanType,
				borrower: fullNameValue,
				closedate: closeDate
			},
			noteInfo: {
				hs_note_body: advisorNotes
			}
		});

		// If we're at /loan-inquiry, perform the following
		if (!atLoanRequestDetailsUrl) {
			submitNewLoanInquiry();
			sendFormData();
		}
		// Else if we're at /loan-inquiry-details, perform the following
		else if (atLoanRequestDetailsUrl) {
			// Call new mutations here patching the new loan inquiry
			updateLoanRequest(loanRequestId);
		}
	};

	// Handlers for mutation
	const { isError: sendFormDataError } = sendFormData;

	return (
		<Box
			component="form"
			noValidate
			autoComplete="off"
			sx={{ height: '100%', width: '100%' }}
		>
			{!atLoanRequestDetailsUrl && (
				<Grid item xs={12}>
					<Typography
						variant="h2Gascogne"
						gutterBottom
						component="div"
						sx={{
							marginTop: 6
						}}
					>
						Construction Loan
					</Typography>

					<Typography
						variant="body2"
						gutterBottom
						component="div"
						sx={{
							marginBottom: 4
						}}
					>
						Fill in the information below to submit a loan request
						to Sora. You will be able to complete and review your
						loan request in the dashboard task bar.
					</Typography>
				</Grid>
			)}

			{isLoading || (savingLoanInquiry && <Loader />)}
			{!isLoading && !savingLoanInquiry && (
				<>
					<Grid item xs={12} md={6}>
						{sendFormDataError && <Alert variant="error" />}

						{/* Modal shown on successful submission of  */}
						<LoanInquirySuccessModal isOpen={isSuccessModalOpen} />
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							{/* Credit Score */}
							<Input
								label="Credit score"
								value={creditScore}
								onChange={setCreditScore}
								type={NUMBER}
								inputProps={{
									'data-test': 'creditScore'
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							{/* Annual Income */}
							<CurrencyTextInput
								label="Total annual income"
								value={totalAnnualIncome}
								onChange={setTotalAnnualIncome}
								inputProps={{
									'data-test': 'annualIncome'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Loan amount */}
							<CurrencyTextInput
								label="Loan amount"
								subLabel="Amount of loan you would like"
								value={loanAmount}
								onChange={setLoanAmount}
								inputProps={{
									'data-test': 'loanAmount'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Down payment % */}
							<Input
								type="percent"
								label="Down payment %"
								helperText="Down payment cannot be empty"
								onChange={setDownPaymentPercent}
								value={downPaymentPercent}
								inputProps={{
									'data-test': 'downPaymentPercent'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Type of property input */}
							<Input
								label="Type of property (e.g. single home, condo..)"
								value={propertyType}
								onChange={setPropertyType}
								type={TEXT}
								inputProps={{
									'data-test': 'propertyType'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Primary use of property */}
							<Dropdown
								items={TYPES_OF_HOME_PROPERTIES}
								selected={primaryUse}
								onChange={setPrimaryUse}
								variant="outlined"
								label="Primary use of property"
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							{/* Desired loan term */}
							<Input
								label="Desired loan term (# of years)"
								value={desiredLoanTerm}
								onChange={setDesiredLoanTerm}
								type={NUMBER}
								inputProps={{
									'data-test': 'desiredLoanTerm'
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<LoanTypeDropdown
								loanType={selectedLoanType}
								setLoanType={setSelectedLoanType}
								gridColumns={12}
							/>
						</Grid>

						<GetFullAddressPartialForm
							addressLine1={addressLine1}
							addressLine2={addressLine2}
							city={city}
							state={state}
							zipCode={zipCode}
							setAddressLine1={setAddressLine1}
							setAddressLine2={setAddressLine2}
							setCity={setCity}
							setState={setState}
							setZipCode={setZipCode}
							disableAutoComplete={disableAutoComplete}
							setDisableAutoComplete={setDisableAutoComplete}
						/>
						<Grid item xs={12}>
							<SoraTextField
								label="Additional Notes"
								value={additionalNotes}
								onChange={setAdditionalNotes}
							/>
						</Grid>
					</Grid>
				</>
			)}

			<Grid item xs={12} marginTop={4} marginBottom={6}>
				{!isFormReady && (
					<RequiredFieldsTooltip>
						<span>
							<PrimaryButton
								disabled={!isFormReady}
								onClick={submitForm}
							>
								Submit
							</PrimaryButton>
						</span>
					</RequiredFieldsTooltip>
				)}
				{isFormReady && (
					<PrimaryButton onClick={submitForm}>Submit</PrimaryButton>
				)}
			</Grid>
		</Box>
	);
}

export default ConstructionForm;
